.nav__links {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: unset;
    height: unset;
    padding: 0;
}

.nav__links li {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
}

.nav__links li a {
    transition: all 0.3s ease 0s;
    color: #373737;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 10px;
}

.nav__links li a:hover,
.nav__links li a:active,
.nav__links li a.active {
    color: #761418;
}

.vertical-line__header {
    height: 2.5rem;
    border-left: solid #761418;
}

@media screen and (max-width: 767px) {
    .vertical-line__header {
        display: none;
    }

    .nav__links {
        background-color: #f6f6f6;
        margin: 0;
        padding: 1rem 2rem;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
    .nav__links li a {
        font-size: 1rem;
    }
}