.beginning-page__coaching {
    margin: 0;
    background-image: url('../../assets/images/colorFlowers.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.life-coaching__container,
.excecutive-coaching__container {
    display: flex;
    padding: 4rem;
    justify-content: space-around;
    align-content: center;
    text-align: center;
}


.life-coaching__container {
    background-color: #eebba24f;
}

.excecutive-coaching__container {
    background-color: #ecededba;
}

.life-coaching__title {
    margin-right: 5rem;
}

.excecutive-coaching__title {
    margin-left: 5rem;
}


.life-coaching__title h2,
.excecutive-coaching__title h2 {
    color: #761418;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
}

.life-coaching__title p,
.excecutive-coaching__title p {
    font-size: 1.5rem;
    max-width: 45rem;
    font-weight: 500;
    margin-bottom: 30px;
}

@media screen and (max-width: 767px) {

    .life-coaching__container,
    .excecutive-coaching__container {
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .excecutive-coaching__container {
        flex-direction: column-reverse;
    }

    .life-coaching__container img,
    .excecutive-coaching__container img {
        max-width: 100%;
    }


    .life-coaching__title,
    .excecutive-coaching__title {
        margin: 0;
    }

    .life-coaching__title h2,
    .excecutive-coaching__title h2 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .life-coaching__title p,
    .excecutive-coaching__title p {
        font-size: 0.8rem;
    }

}

@media screen and (min-width: 768px) and (max-width: 1280px) {

    .life-coaching__container,
    .excecutive-coaching__container {
        padding: 1rem;
    }

    .life-coaching__title,
    .excecutive-coaching__title {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .life-coaching__title h2,
    .excecutive-coaching__title h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .life-coaching__title p,
    .excecutive-coaching__title p {
        font-size: 1rem;
        text-align: justify;
    }

    .life-coaching__container img,
    .excecutive-coaching__container img {
        max-width: 50%;
        margin: 1rem;
    }
}