.header-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    padding: 1rem;
    background-color: #f6f6f6;
    position: relative;
}

.header-logo img {
    cursor: pointer;
    width: 180px;
}

.header__nav-desktop {
    display: block;
}

.header-toggle {
    width: 3rem;
    height: 2.2rem;
    background: transparent;
    border: none;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
    position: absolute;
    right: 0;
}

.header-toggle span {
    display: block;
    width: 100%;
    height: 3px;
    background: black;
}

@media screen and (max-width: 767px) {
    .vertical-line__header {
        display: none;
    }

    .header-logo img {
        width: 100px;
    }

    .header-toggle {
        display: block;
    }

    .nav__links {
        background-color: #f6f6f6;
        position: absolute;
        right: 0;
        top: 0vh;
        padding: 0;
        flex-direction: column;
    }

    .header__nav-desktop {
        display: none;
    }

    .header-toggle {
        display: flex;
    }


}

@media screen and (min-width: 768px) and (max-width: 1280px) {

    .header-container {
        padding: 0.5rem;
    }

    .header-container {
        min-height: 5vh;
    }

    .header-logo img {
        width: 150px;
    }

    .nav__links li a {
        font-size: 1rem;
    }

}