  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }

  .form-control label {
    font-weight: 400;
    margin-bottom: 0.4rem;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: inherit;
  }


  .form-control input,
  .form-control textarea {
    width: 30rem;
    font: inherit;
    border-radius: 4px;
    background: #f8f8f8;
    padding: 0.15rem 0.25rem;
    box-sizing: border-box;
    border: solid 2px #761418;
  }

  .form-control input {
    height: 50px;
  }

  .form-control textarea {
    height: 100px;
    max-height: 200px;
    min-height: 50px;
    resize: vertical;
  }


  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border: solid 2px #510077;
  }



  @media screen and (max-width: 767px) {
    .form-control label {
      font-size: 1.2rem;
    }
  }