

.title-container hr{
    height: 5px;
    background: radial-gradient(100% 544.44% at 100% 0%, #761418 57.63%, #C00C1D 100%);
    margin: 0;
    padding: 0;
}

.title-text{
    background-image: url('../../assets/images/simpleBackgroundTitle.png');
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    padding: 4rem;
}

.title-text h1{
    font-size: 3.5rem;
}

.title-text___extend{
    background-image: url('../../assets/images/extendBackgroundTitle.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    display: flex;
    align-items: center;
    padding: 0rem 4rem;  
}

.title-text___extend h1{
    font-size: 4rem;
    font-weight: 700;
}

.title-text___extend h2{
    color: #761418;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.title-text___extend p{
    font-size: 1.5rem;
    max-width: 60rem;
    font-weight: bold;
}
.vertical-line__simple{
    width: 5px;
    height: 8rem;
    margin-right: 1rem;
    border-left: thick solid #761418; 
}

.vertical-line__extend{
    width: 5px;
    height: 20rem;
    margin-right: 1rem;
    border-left: thick solid #761418; 
}

@media screen and (max-width: 767px){
    .title-text h1{
        font-size: 2rem;
    }

    .title-text___extend{
        min-height: 50vh;
        padding: 1rem;
    }

    .title-text___extend h1{
        font-size: 2rem;
    }

    .title-text___extend h2{
        font-size: 1.5rem;
    }

    .title-text___extend p{
        font-size: 0.8rem;
    }

    .vertical-line__extend{
        height: 15rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
    .title-text h1,
    .title-text___extend h1{
        font-size: 3rem;
    }

    .title-text___extend{
        min-height: 40vh;
        padding: 2rem;
    }

    .title-text___extend h2{
        font-size: 2rem;
    }

    .title-text___extend p{
        font-size: 1rem;
    }

    .vertical-line__extend{
        height: 15rem;
    }

}