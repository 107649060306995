.contact-form__container{
    background-image: url('../../assets/images/whiteFlowers.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ab0c1b;
    display: flex;
    justify-content: space-evenly;
    padding: 4rem;
}

.contact-form__container form{
    max-width: 70rem;
}

.contact-form__container form h2{
    font-size: 3rem;
    font-weight: bold;
    color: white;
    text-align: center;
    margin-bottom: 2rem;
}

.contact-form__container form button{
    margin-top: 4rem;
}


.form-body input{
    margin-bottom: 1rem;
}

.contact-form__images{
    display: flex;
    align-items: center;
}

.form-terms{
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.form-terms p{
    font-size: 1.5rem;
    font-weight: 500;
    margin-left: 1rem;
    color: white;
}

.form-terms input {
    width: 1rem;
    height: 1rem;
}

@media screen and (max-width: 767px){
    .contact-form__container{
        display: block;
        padding: 1rem;
    }

    .form-body{
        width: 100%;
    }

    .form-body input,
    .form-body textarea{
        width: 100%;
    }

    .contact-form__container form h2{
        font-size: 1.5rem;
    }

    .contact-form__images{
        display: flex;
        justify-content: center;
    }
    .contact-form__images img{
        max-width: 90%;
    }

    .contact-form__container form button{
        margin-top: 3rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
    .contact-form__container{
        padding: 2rem;
    }

    .contact-form__images img{
        width: 100%;
    }
    .form-body{
        max-width: 80%;
    }
    .form-body input,
    .form-body textarea{
        max-width: 100%;
    }
}