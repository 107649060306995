.beggining-page__programs {
    padding: 4rem;
}

.programs-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
}

.programs-header__description,
.programs-header__invitation {
    display: flex;
}

.programs-header__invitation-detail {
    text-align: center;
}

.programs-header__description p,
.programs-header__invitation-detail p,
.programs-body__text p {
    font-size: 1.5rem;
    font-weight: 500;
}

.programs-header__description img {
    max-height: 10rem;
}

.programs-header__invitation-detail p {
    margin-bottom: 2rem;
}

.programs-header__description-text {
    margin-top: 1rem;
    max-width: 40rem;
}

.programs-header__description span {
    color: #761418;
}

.programs-header__invitation-detail h2 {
    color: #761418;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.programs-body {
    display: flex;
    justify-content: space-around;
    margin-top: 4rem;
}

.programs-body__text {
    display: flex;
}

.programs-body__text p {
    max-width: 50rem;
    text-align: justify;
    margin-right: 2rem;
}

.vertical-line__invitation {
    width: 5px;
    height: 70%;
    margin-right: 2rem;
    border-left: thick solid #761418;
}

.vertical-line__programs-body {
    width: 5px;
    height: 25rem;
    margin-right: 2rem;
    border-left: thick solid #761418;
}

.programs-body__img-content img {
    max-width: 60vh;
}

.programs-body__img-footer {
    margin-top: 15px;
}

.programs-body__img-footer p {
    font-weight: bold;
    font-size: 1.5rem;
    color: #761418;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .beggining-page__programs {
        padding: 1rem;
    }

    .programs-header {
        display: block;
    }

    .vertical-line__invitation {
        display: none;
    }

    .programs-header__description p {
        text-align: justify;
        margin-left: 10px;
    }

    .programs-header__description p,
    .programs-header__invitation-detail p,
    .programs-body__text p {
        font-size: 0.8rem;
    }

    .programs-header__description img {
        width: 100px;
    }

    .programs-header__invitation-detail h2 {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .programs-header__invitation-detail p {
        margin-bottom: 1rem;
    }

    .programs-body {
        display: block;
        margin-top: 1rem;
    }

    .programs-body__text {
        align-items: center;
        margin-bottom: 1rem;
    }

    .vertical-line__programs-body {
        height: 15rem;
    }

    .programs-body__img-content img {
        width: 100%;
    }

    .programs-body__img-footer p {
        padding: 0;
        text-align: center;
    }

    .programs-body__img-footer p {
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
    .beggining-page__programs {
        padding: 2rem;
    }

    .programs-header__invitation-detail h2 {
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .vertical-line__invitation {
        margin-right: 1rem;
    }

    .programs-header__description p,
    .programs-header__invitation-detail p,
    .programs-body__text p {
        font-size: 1rem;
    }

    .programs-body__text {
        align-items: center;
        margin-bottom: 1rem;
        justify-content: center;
    }

    .programs-body__text p {
        margin: 0;
    }

    .programs-body {
        display: block;
        margin-top: 0;
    }

    .vertical-line__programs-body {
        margin-right: 1rem;
        height: 15rem;
    }

    .programs-body__img {
        padding: 1rem 4rem 0rem 4rem;
    }

    .programs-body__img-content img {
        width: 100%;
    }

    .programs-body__img-footer {
        display: block;
        padding: 0rem;
    }

    .programs-body__img-footer p {
        display: block;
        padding: 0rem;
    }
}