.button{
    font: inherit;
    display: block;
    width: 250px;
    padding: 0.5rem 0rem;
    border-radius: 6px;
    border: none;
    margin: 15px auto;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    background: radial-gradient(100% 444.44% at 100% 0%, #C00C1D 20%,#761418 100%);
    cursor: pointer;
    text-align: center;
}

.button:focus {
    outline: none;
}

.button--disabled{
    background: #b1b1b1;
    color: white;
}

.request__proof{
    display: flex;
    align-items: center;
    justify-content: center;
}
.request__proof img{
    margin-right: 15px;
}