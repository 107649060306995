.footer-container {
    background-color: #761418;
    padding-top: 2px;
}

.footer-info {
    margin-top: 5px;
    padding: 4rem 2rem;
    background-color: #f6f6f6;
    display: flex;
    text-align: center;
    font-weight: bold;
    justify-content: space-around;
}

.footer-links {
    display: grid;
    justify-content: center;
}

.footer-links p {
    margin-bottom: 5px;
}

.footer-links a {
    color: #761418;
    text-decoration: none;
}

.footer-contact__title,
.footer-networks__title {
    margin-bottom: 20px;
}

.footer-contact__info {
    display: grid;
    justify-content: center;
}

.footer-contact__info-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.footer-contact__info-detail img {
    margin-right: 10px;
}

.footer-networks__links a {
    margin-right: 5px;
}


.footer-property {
    padding: 1rem 4rem;
    display: flex;
    justify-content: center;
}

.footer-property p {
    color: white;
}

.footer-property a {
    text-decoration: none;
    color: white;
}

.footer-contact__licenseImage {
    margin-top: 10px;
}

.footer-links img {
    width: 100%;
}



@media screen and (max-width: 767px) {
    .footer-info {
        display: block;
    }

    .footer-info {
        padding: 1rem;
    }

    .footer-contact__title,
    .footer-networks__title {
        margin-bottom: 0.5rem;
    }

    .footer-links,
    .footer-contact {
        margin-bottom: 2rem;
    }
}