.beggining-page__tips{
    background-color: #ecededba;
    padding: 4rem;
}

.tips-title{
    text-align: center;
    margin-bottom: 2rem;
}

.tips-body{
    display: flex;
    justify-content: space-evenly;
}

.tips-tittle{
    margin-bottom: 2rem;
}

.tips-tittle h2{
    color: #761418;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
}

.tips-body p{
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    max-width: 40rem;
}

.tips-body p span{
    color: #761418;
}

.tips-container__form{
    margin-left: 2rem;
}

.tips-container__form form{
    display: flex;
}

.tips-container__form form input{
    margin-top: 0.8rem;
    margin-right: 2rem;
}


@media screen and (max-width: 767px){
    .beggining-page__tips{
        padding: 1rem;
    }
    .tips-tittle{
        margin-bottom: 0.5rem;
    }
    .tips-tittle h2{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .tips-body p{
        text-align: justify;
        font-size: 0.8rem;
    }

    .tips-body{
        display: block;
    }
    .tips-container__form{
        margin: 0;
    }
    .tips-container__form form{
        display: block;
    }
    .tips-container__form form input{
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px){
    .beggining-page__tips{
        padding: 2rem;
    }

    .tips-tittle{
        margin-bottom: 1rem;
    }
    .tips-tittle h2{
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .tips-body p{
        text-align: justify;
        font-size: 1rem;
        padding: 1rem;
    }

    .tips-container__form {
        width: 100%;
    }
    .tips-container__form form{
        display: block;
    }
    
    .tips-container__form form input{
        width: 100%;
    }
}
