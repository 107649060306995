* {
  box-sizing: border-box;
  outline: none;
}

body {
  background-color: #FFFFFF;
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p span {
  font-weight: bold;
}

.slide-in-left-enter {
  transform: translateY(-100%);
}

.slide-in-left-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}

.fallback {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}